import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import checkElements from '../../checkElements';
const mainListItems = [
  { text: 'HOME', path: '/', icon: <HomeRoundedIcon /> },

];

export default function MenuContent({ handleClick, currentPath, readManga, handleMangaClick }) {
  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={currentPath === item.path}
              onClick={() => handleClick(item)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} sx={{ fontSize: '2.5em' }} />
            </ListItemButton>
            <Box sx={{ padding: 2, textAlign: 'center' }}>
              <Divider></Divider>
              <AutoStoriesIcon sx={{ marginTop: '1em' }} />
              <Typography variant="h6" color="text.primary" sx={{ marginBottom: 2 }}>
                Manga History
              </Typography>
              {readManga.length == 0 ? <Typography sx={{ color: 'gray', fontSize: '0.75em' }}>No history</Typography> : null}
              {readManga.map((itemm, index) => (

                <ListItemButton
                  selected={currentPath === itemm.name}
                  onClick={() => {handleMangaClick(itemm); checkElements()}}
                >

                  <ListItemText sx={{ fontSize: '2.5em' }}>{itemm.name}<br />Pages {(itemm.page - 1) * 5 + 1} - {itemm.page * 5} | V{itemm.vol}</ListItemText>
                </ListItemButton>
              ))}
            </Box>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
