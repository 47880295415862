import {useRef, useEffect} from 'react';

export function openPopUnder() {
    try {
        const adUrl = 'https://knowledgeable-tree.com/bG3IVr0.Ps3vpmv/bjm/VQJ/ZrDF0j1-NpDLg/5MMzDQcc4zLiT/Uz0lOfDekWwtNjz/k-'; // URL of the pop-under ad
        const adWindow = window.open(adUrl, 'popunder', 'width=600,height=400,scrollbars=yes');
    
        // If the pop-under window is successfully created, it will be moved behind the main window
        if (adWindow) {
          adWindow.blur();
          window.focus();
        }
    } catch (e) {
        console.error(e)
    }

  }

export const AdComponent = ({ id }) => {
    const adRef = useRef(null);
  
    useEffect(() => {
        try {

        
      // Function to create and append the script
      const addScript = () => {
        if (adRef.current) {
          const script = document.createElement('script');
          script.src = "https://terrificdark.com/blXbV.sJdYGClh0vYUW/dzi/YAWm5vukZ-X/Ig/teymC9fuYZgU/lJkxPMTTU_0FOgDgksxtN/jLANtaNzTYQa4sOUTfEL2PMNQe";
          script.async = true;
          script.referrerPolicy = 'no-referrer-when-downgrade';
  
          adRef.current.innerHTML = ''; // Clear previous content
          adRef.current.appendChild(script);
        }
      };
  
      addScript();
  
      // Cleanup function to remove the script
      return () => {
        if (adRef.current) {
          adRef.current.innerHTML = ''; // Clear script on cleanup
        }
      };
    } catch(e) {
        console.error(e);
    }
    }, [id]); // Dependency on `id` if necessary for different ad zones
  
    return <div ref={adRef} id={id} />;
  };