import logo from './logo.svg';
import './App.css';
import Page from './page/Page'
function App() {
  return (
    <div className="App">
      
      <Page />
    </div>
  );
}

export default App;
