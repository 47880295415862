import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { FormControl } from '@mui/material';
import checkElements from '../../checkElements';

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create(['width', 'border-color']),

    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',

      },
    },
  },
}));

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha('#aaa', 0.35),
  '&:hover': {
    backgroundColor: alpha('#aaa', 0.5),
    borderColor: '#8A2BE2',  // Slightly more faded outline on hover
  },
  marginLeft: 0,
  marginBottom: '1em',

  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: '30%',
    border: '2px solid transparent',
    '&:hover': {
      borderColor: alpha('#8A2BE2', 0.5),  // Faded outline on hover
    },
  },
}));

const SearchBar = ({setSearch}) => {
  const [input, setInput] = useState('');

  return (
    <form className="d-flex fade-in-target" style={{ margin: '1em' }} onSubmit={(e) => {e.preventDefault(); checkElements(); setSearch(input); console.log(input)}} onKeyDown={(key) => key.key == ' '} >
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>

        <StyledInputBase
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
          onChange={(e) => setInput(e.target.value)}
        />
      </Search>
    </form>
  );
};

export default SearchBar;
