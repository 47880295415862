import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Box, CircularProgress, Typography } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import AutoScrollBtn from '../components/AutoScrollBtn';
import Cookies from 'js-cookie';
import { MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import mangas from './manga.json';
import { openPopUnder } from '../ads';
import { AdComponent } from '../ads';
import useScrollHandler from '../components/useScrollHandler';
import { SwitchLang } from './SwitchLang';
import checkElements from '../../checkElements';
const Dropdown = ({setCurrVol, currVol, setCurrentPage}) => {
  // State to manage the volume options
  const [vs, setVs] = useState([]);
  const location = useLocation();
  const manga = decodeURIComponent(location.pathname.split('/')[2])
  useEffect(() => {
    // Generate an array from 1 to the number of volumes

    if (mangas[manga]) {
      const volumeCount = mangas[manga].vols;
      setVs(Array.from({ length: volumeCount }, (_, index) => index + 1));
    }
  }, [manga]);

  const [selectedValue, setSelectedValue] = useState(currVol);

  // Handler for when the selection changes
  const handleChange = (event) => {
    setSelectedValue(Number(event.target.value));
    setCurrVol(Number(event.target.value));
    setCurrentPage(1);
  };

  return (
    <FormControl variant="outlined">
      <InputLabel
        id="dropdown-label"
        shrink={true} // Ensure the label is always visible
        style={{ 
          transform: 'translate(14px, 12px) scale(0.75)', // Adjust positioning
          color: '#8A2BE2'
        }}
      >
        Select a Volume
      </InputLabel>
      <Select
        labelId="dropdown-label"
        id="dropdown"
        value={selectedValue || ''}
        onChange={handleChange}
        label="Select a Volume"
      >
        {vs.map((vol, index) => (
          <MenuItem key={index} value={index + 1}>
            {vol}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
const StreamedFileDownloader = ({ manga, setReadManga, vol }) => {
  const [images, setImages] = useState([]);
  const [paginatedImages, setPaginatedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  
  const navigate = useNavigate();
  const location = useLocation();
  const [manganame, setMangaName] = useState(decodeURIComponent(location.pathname.split('/')[2]))

  const [currVol, setCurrVol] = useState(vol);

  const { isScrolling, handleScroll } = useState(false);
  const [lang, setLang] = useState('日本語');

  useEffect(() => {
    setImages(prev => {
      setMangaName(manga);
      return [];
    });

  }, [manga])

useEffect(() => {
  const queryParams = new URLSearchParams(location.search);
  const volx = queryParams.get('vol');
  const pagex = queryParams.get('page');
  if(queryParams.get('lang')) {
    setLang(queryParams.get('lang'));
  }
  if(volx && pagex) {
    
    console.log(manganame)
    setImages([])
    console.log(location.pathname.split('/')[2]);
    setCurrVol(Number(volx))
    setCurrentPage(Number(pagex))
    console.log(currVol, currentPage)
  }
}, [location.pathname, manganame])
useEffect(() => {
  checkElements();
}, [])
  // Fetch comic images based on the current page
  const fetchAndDisplayComic = useCallback(async () => {
    const queryParams = new URLSearchParams(location.search);
    if(!manganame) {
      setMangaName(decodeURIComponent(location.pathname.split('/')[2]))
      
      const volx = queryParams.get('vol');
      const pagex = queryParams.get('page');
      if(volx && pagex) {
        
        console.log(manganame)
        setImages([])
        console.log(location.pathname.split('/')[2]);
        setCurrVol(Number(volx))
        setCurrentPage(Number(pagex))
        console.log(currVol, currentPage)
      }
      return;
    }
    if(queryParams.get('lang')) {
      setLang(queryParams.get('lang'));
    }
    setIsLoading(true);
    setError(null);

    try {
      const sessID = localStorage.getItem('session-id' );
      const urlResponse = await fetch(`https://hazymanga.com/XaZvwPy?t=${sessID || 'abc'}&manga=${manganame}&vol=${currVol}&page=${currentPage}&lang=${lang}`);
    
    if (!urlResponse.ok) {
      return setError("Unauthorized");
    }
    const urlJSON = await urlResponse.json();
      const response = await fetch(urlJSON.encrUrl);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // Ensure that data.images is an array
      if (Array.isArray(data.images)) {
        setImages(prevImages => {
          setPaginatedImages(data.images);
          setError(null)
          return data.images;
        });
        console.log(images.length)
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (err) {
      setError("Failed to retrieve manga, please try again later.");
      console.error('Fetch error:', err);
    } finally {
      setIsLoading(false);
    }
  }, [manganame, currVol, currentPage, lang]);

  useEffect(() => {
    fetchAndDisplayComic(currentPage);
  }, [fetchAndDisplayComic]);

  const handleNextPage = () => {
    setIsLoading(true);
    if(Math.random() < 0.45) {
      openPopUnder();
    }
    setCurrentPage(prevPage => {
      const newPage = Number(prevPage) + 1;
      const newEntry = { page: newPage, name: (manga && manga.length > 16 ? manga.slice(0, 15) + '...':manga) || (manganame && manganame.length > 16 ? manganame.slice(0, 15) + '...':manganame), vol: currVol };

      // Retrieve and parse the existing cookie value
      try {
        const existingCookie = Cookies.get('readmangas');
        let existing = [];
        if(existingCookie){
          existing = JSON.parse(existingCookie);
          if(existing.length > 5) {
            existing.pop();
          }
        }

        // Add the new entry and remove the last one if there are already 5 entries
        const updated = [newEntry, ...existing]
        .filter((entry, index, self) =>
          index === self.findIndex((e) => e.name === entry.name)
        )
        .slice(0, 10); // Keep only the latest 10 entries
  
        // Store the updated array in cookies
        Cookies.set('readmangas', JSON.stringify(updated), { expires: 7 }); // Cookie expires in 7 days
  
        // Update the state
        setReadManga(updated);
  
      } catch (e) {
        console.error('Error handling cookies:', e);
        // Handle error (e.g., reset to empty array or show error message)
        setReadManga([]);
      }
  
      // Scroll to top
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(() => {
        setImages([])
      }, 400)
      return newPage;
    });
  };

  const handlePreviousPage = () => {
    setIsLoading(true);
    if(Math.random() < 0.45) {
      openPopUnder();
    }
    setCurrentPage(prevPage => {
      const newPage = Math.max(prevPage - 1, 1);
  
      // Store the manga name and new page number in cookies
      Cookies.set('currentPage', newPage, { expires: 7 }); // Cookie expires in 7 days
      Cookies.set('vol', vol, { expires: 7 }); // Cookie expires in 7 days
      Cookies.set('mangaName', manga || manganame, { expires: 7 }); // Store manga name
  
      return newPage;
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      setImages([])
    }, 400)
  };


  return (
    <Box sx={{ textAlign: 'center', padding: 2 }}>
      {Math.random() < 0.2 ? <AdComponent id="chanceAd"/>:null}
      <SwitchLang lang={lang} setLang={setLang}></SwitchLang>
      <Dropdown setCurrVol={setCurrVol} currVol={currVol} setCurrentPage={setCurrentPage}/>
      <AutoScrollBtn/>
      <Button variant="contained" color="primary" onClick={() => navigate('/')} disabled={isLoading}  className='fade-in-target'>Back</Button>
      
      {error && <Typography color="error" variant="body2">{error}</Typography>}
      {        !error  ?
        <Typography variant="body1" sx={{ marginTop: 2, display: isLoading ? 'none' : 'block' }}  className='fade-in-target'>
          Pages {((currentPage - 1) * itemsPerPage) + 1 } - {((currentPage) * itemsPerPage)}
        </Typography>:null
      }
      <Box sx={{ display: 'flex',width: {xs: "95%", md: "75%"}, margin: 'auto', flexDirection: "column", flexWrap: 'wrap', justifyContent: 'center', marginTop: 2 }}>
        {paginatedImages.map((imageUrl, index) => (
          <img
          style={{ width: "100%", height: "auto", marginBottom: '10px', borderRadius: '8px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}
            key={index}
            src={imageUrl}
            alt={`Comic Page ${index + 1}`}
            
          />
        ))}
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={handlePreviousPage}
            sx={{
              position: 'fixed',
              top: '50%',
              left: { xs: '10px', md: '250px' },
              transform: 'translateY(-50%)',
              display: isScrolling ? 'none' : { xs: 'flex', sm: 'flex' },
              flexDirection: { xs: 'column', sm: 'row' },
              width: {xs: '2.75em', md: '6.5em'}
            }}
            startIcon={<ArrowBack />}
            disabled={currentPage === 1}
             className='fade-in-target'
          >
            <Typography variant="body2" sx={{display: {xs: 'none', md: 'block'}}}>Previous</Typography>
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              position: 'fixed',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
              display: isScrolling ? 'none' : { xs: 'flex', sm: 'flex' },
              flexDirection: { xs: 'column', sm: 'row' },
              width: {xs: '2.75em', md: '6.5em'}
            }}
             className='fade-in-target'
            onClick={(e) => handleNextPage()}
            // disabled={currentPage * itemsPerPage >= images.length + 1}
            endIcon={<ArrowForward />}
          >
            <Typography variant="body2" sx={{display: {xs: 'none', md: 'block'}}}>Next</Typography>
          </Button>
        </Box>
        {isLoading ? <CircularProgress size={36} /> : null}
        {        !error  ?
        <Typography variant="body1" sx={{ marginTop: 2 , display: isLoading ? 'none' : 'block'}}  className='fade-in-target'>
          Pages {((currentPage - 1) * itemsPerPage) + 1 } - {((currentPage) * itemsPerPage)}
        </Typography>:null
      }

      </Box><Box sx={{display: "flex", flexDirection: {xs: 'column', md: 'row'}}}><AdComponent id="btmReadAd1" /><AdComponent id="btmReadAd2"/><AdComponent id="btmReadAd3"/></Box>
    </Box>
  );
};

export default StreamedFileDownloader;
