import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuContent from './MenuContent';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
});

export default function SideMenu({ readManga, setManga}) {
  const navigate = useNavigate();
  const location = useLocation();  // Get the current location (URL path)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  
  const isMobile = screenWidth <= 600;
  const isTablet = screenWidth > 600 && screenWidth <= 899;
  const isDesktop = screenWidth > 900;
  const [menuDisplay, setMenuDisplay] = useState('flex')
  const [show, setShow] = useState(false);
  React.useEffect(() => {
    if(isTablet) {
      setShow(false)
    } else if(isDesktop) {
      setShow(false)
    }
  }, [])
  // Effect hook to update screen width on resize
  React.useEffect(() => {
    // Define the function to handle window resize
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener on mount
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // Handles click on menu items and manga list
  const handleClick = (item) => {
    navigate(item.path);

  };
  const handleMangaClick = (item) => {
    setManga(item.name)
    navigate(`/read/${item.name}?vol=${item.vol}&page=${item.page}`);
    
  }

  const toggleDrawer = () => {
    if(show) {
      setMenuDisplay('flex')
    } else {
      setMenuDisplay('none')
    }
    setShow(!show);
  };
  return (
    <>
    <Box sx={{p: 0.5, m:1.5 ,borderRadius: '12px',  display: {xs: menuDisplay, md: 'none'}, alignItems: 'center', justifyContent: 'center',zIndex: 4000,position: 'fixed', cursor: 'pointer', ['&:hover']: {backgroundColor: 'rgba(255,255,255,0.1)', color: 'purple'}}} onClick={toggleDrawer}><MenuIcon sx={{fontSize: '3em'}}/>
    </Box>
    <Drawer
    variant={!isDesktop  ? 'temporary':'permanent'}
    open={show}
    onClose={toggleDrawer}
      sx={{


        [`& .${drawerClasses.paper}`]: {
          backgroundColor: 'background.paper',
          backgroundImage: "url(/navbg.webp)",
          border: 'none',
          boxShadow: "0px 0 8px 4px black",
          height: '100vh',
          overflowY: 'hidden'
        },
      }}
    >
      <Box onClick={toggleDrawer} sx={{cursor: 'pointer', borderRadius: '12px', zIndex: 5000, width: '3.5em', height: '3.5em', position: 'absolute', left: '180px', top: '50%', transform: 'translateY(-50%)', display: {xs: 'flex', md: 'none'}, alignItems: 'center', justifyContent: 'center', ['&:hover']: {backgroundColor: 'rgba(0,0,0, 0.85)'}}}><NavigateBeforeIcon sx={{fontSize: '2.5em'}}/></Box>
      
      <Box style={{
        backgroundColor: 'rgba(0, 0,0,0.9)',
        border: 'none',
        height: '100%',
        overflowY: 'hidden',  // Ensure scrolling if the list is long
        
      }}>
        <Box
          sx={{
            width: 200,
            height: 200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '8px',
            overflow: 'hidden',
            margin: 'auto',
            mt: 2,
          }}
        >
          <img
            src="/logo.png"
            alt="Example"
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </Box>
        <Divider />
        <MenuContent handleClick={handleClick} currentPath={location.pathname} readManga={readManga} handleMangaClick={handleMangaClick} />
        <Divider />
        {/* Manga List */}
          <Divider/>
          <Typography sx={{mt: 2}}><a href="mailto:contact@hazymanga.com">contact@hazymanga.com</a>
          </Typography>
      </Box>
    </Drawer>
    </>
  );
}
