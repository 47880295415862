function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
  
    // Adjust visibility threshold to 10%
    const threshold = 0.1;
  
    return (
      (rect.top + rect.height * threshold) <= viewportHeight &&
      (rect.bottom - rect.height * threshold) >= 0 &&
      (rect.left + rect.width * threshold) <= viewportWidth &&
      (rect.right - rect.width * threshold) >= 0
    );
  }
  export default async function checkElements() {
    const elements = document.querySelectorAll('.fade-in-target');
  
    for (const el of elements) {
      // Check if element is in viewport and has not been animated yet
      if (isElementInViewport(el) && !el.classList.contains('animated')) {
        el.classList.add('fade-in-forward', 'animated');
        el.classList.remove('fade-in-target');
  
  
        await new Promise((res) => setTimeout(res, 50));
      }
    }
  }