import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Button, Divider, Container } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import mangas from '../manga/manga.json'; // Ensure this path is correct
import CategoryIcon from '@mui/icons-material/Category';
import { openPopUnder } from '../ads';
import { AdComponent } from '../ads';
import ViewSimilar from './ViewSimilar';
import checkElements from '../../checkElements';
import DynamicMetaComponent from './DynamicMetaComponent';
const MangaProfile = ({ setVol, setManga, manga }) => {
    const { mangaId } = useParams(); // Get mangaId from URL params
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [mangaData, setMangaData] = useState({})


    const handleVolClick = (vol) => {
        if (Math.random() < 0.4) {
            openPopUnder();
        }
        setVol(vol);
        setManga(mangaId)
        navigate('/read/' + mangaId);
    }

    const handleEngClick = () => {
        if (Math.random() < 0.4) {
            openPopUnder();
        }
        setVol(1);
        setManga(mangaId)
        navigate('/read/' + mangaId + '?lang=eng');
    }
    useEffect(() => {
        
        const fetchManga = () => {
            if (!manga) {
                setManga(mangaId);
            }
            const decodedId = decodeURIComponent(mangaId); // Decode mangaId from URL params
            // Normalize the key format if needed
            const normalizedId = decodedId.replace(/%20/g, ' ').replace(/#/g, '');
            const mangaData = mangas[normalizedId]; // Access manga data using the normalizedId

            if (mangaData) {
                setMangaData(mangaData);
            } else {
                console.error('Manga not found');
                // Handle manga not found case, e.g., redirect or show an error
            }
            setLoading(false);
        };

        fetchManga();
        setTimeout(checkElements, 250)
    }, [mangaId]);

    if (!manga) {
        return <Typography variant="h6">Manga not found</Typography>;
    }

    // Generate buttons for each volume
    const volumeButtons = Array.from({ length: mangaData.vols }, (_, index) => (
        <Button
            key={index + 1}
            variant="contained"
            color="secondary"
            sx={{ mt: 1, mr: 1 }}
            onClick={() => handleVolClick(index + 1)} // Replace with appropriate action
            className='fade-in-target'
        >
            {index + 1}
        </Button>
    ));

    return (
        <Container sx={{ mt: 8, p: 0 }}>
                  <DynamicMetaComponent title={decodeURIComponent(mangaId) + " | Read Online | HazyManga"} description={"Read manga free online. " + mangaData.desc} imageUrl={"https://hazymanga.com/covers" + mangaData.img}/>

            <Card>
                <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', justifyContent: 'flex-start' }}>
                        <CardMedia sx={{ width: 'fit-content', }}
                        ><Box component="img"
                            height="200px"
                            width='auto'
                            src={'/covers' + mangaData.img} // Fallback image path
                            alt={decodeURIComponent(mangaId)}
                            sx={{
                                objectFit: 'contain',
                            }}
                            className='fade-in-target'></Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                {mangaData && mangaData.v && mangaData.v.raw === true ? <Typography
                                    variant="body2"
                                    onClick={() => handleVolClick(1)}
                                    sx={{
                                        cursor: 'pointer',
                                        borderRadius: '4px',
                                        fontSize: '0.8em',
                                        backgroundColor: '#0fd71c',
                                        width: '4em',
                                        p: 0.4,
                                        mb: 1
                                    }}
                                     className='fade-in-target'
                                >
                                    RAW
                                </Typography> : null}
                                {mangaData && mangaData.v && mangaData.v.eng === true ? <Typography
                                    onClick={handleEngClick}
                                    variant="body2"
                                    sx={{
                                        cursor: 'pointer',
                                        borderRadius: '4px',
                                        fontSize: '0.8em',
                                        backgroundColor: '#0085f1',
                                        width: '4em',
                                        p: 0.4,
                                    }}
                                     className='fade-in-target'
                                >
                                    ENG
                                </Typography> : null} </Box>
                        </CardMedia>
                        <Box sx={{ textAlign: 'left', pl: 1, mt: 4 }} >
                            <Typography variant="h4" component="div"  className='fade-in-target'>
                                {decodeURIComponent(mangaId) + ' - ' + mangaData.jp}
                            </Typography>

                            <Typography variant="body1" sx={{ mt: 1 }}  className='fade-in-target'>
                                {mangaData.desc} <br /> <br />Read online for free now.
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}  className='fade-in-target'>
                                Release date: {mangaData.releaseDate}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}  className='fade-in-target'>
                                <CategoryIcon sx={{ m: 0 }} />
                                {mangaData && mangaData.tags && mangaData.tags.map((tag, index) => {
                                    return <Typography sx={{ mt: 1, p: 2, width: 'max-content', color: 'gray', display: 'inline-block' }}>{tag}</Typography>
                                })}
                            </Box>
                        </Box>

                    </Box>
                    <Button
                        onClick={(e) => { e.preventDefault(); handleVolClick(1) }}
                        variant="contained"
                        color="primary"
 className='fade-in-target'
                        style={{ marginTop: '10px' }}
                    >
                        Read Now
                    </Button>
                    <Divider sx={{ my: 2 }}  className='fade-in-target'/>

                    <Typography variant="h6"  className='fade-in-target'>Volumes:</Typography>
                    <Typography variant="body2"  className='fade-in-target'>
                        {mangaData.vols}
                    </Typography>
                    <Box sx={{ mt: 2, mb: 2 }}  className='fade-in-target'>
                        {volumeButtons}
                    </Box>
                    <Box sx={{ mt: 2 }}  className='fade-in-target'>
                        <Button variant="contained" color="primary" onClick={() => navigate('/')}>
                            Back to Manga List
                        </Button>
                    </Box>
                </CardContent>
                <ViewSimilar product={mangaData} data={mangas} />
            </Card>
            <Box sx={{ display: "flex" }}>
                <AdComponent /> <AdComponent /> <AdComponent />     </Box>   </Container>
    );
};

export default MangaProfile;
