import { Box, Typography } from "@mui/material";
import {React, useCallback, useState} from "react";

export const SwitchLang = ({lang, setLang}) => {

    const switchLang = useCallback(() => {
        if(lang === '日本語') {
            return setLang('ENG');
        }
        return setLang('日本語');
    }, [lang])

    return (
        <Box sx={{borderRadius: '6px', cursor: 'pointer', p: 0.6, width: '3.2em', height: '1.55em', position: 'absolute', right: '5px', backgroundColor: lang === '日本語' ? '#0fd71c':'#0085f1'}} onClick={switchLang}>
            <Typography sx={{fontSize: '0.8em', lineBreak: 'no-break'}}>{lang}</Typography>
        </Box>
    )
}