import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Grid, IconButton, Button } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material'; // Import circular arrow icons
import { useNavigate } from 'react-router-dom';
import { openPopUnder } from '../ads';
import checkElements from '../../checkElements';

const ViewSimilar = ({ product, data }) => {
  const [similarItems, setSimilarItems] = useState([]);
  const containerRef = useRef(null); // Ref for the container div
    const navigate = useNavigate();
  // Function to compare the first two elements of each array
  const compareArrays = (arr1, arr2) => {
    if(!arr1) return;
    if(!arr2) return;
    // Create a set to store unique matching tags
    const tagSet = new Set();
  
    // Iterate through arr1 and add matching tags to the set
    arr1.forEach(tag1 => {
      if (arr2.includes(tag1)) {
        tagSet.add(tag1);
      }
    });
  
    // Check if there are at least 2 matching tags
    return tagSet.size >= 2;
  };

  const handleCardClick = (mangaId) => {
    if(Math.random() < 0.3) {
        openPopUnder();

      }
      navigate('/manga/' + mangaId);
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  // Function to get similar items based on tags
  const getSimilarItems = () => {

    const tags = product.tags;

    // Filter items based on tags and ensure the product jp is different
    const filteredItemsWithKeys = Object.entries(data).flatMap(([key, item]) => {
      return compareArrays(item.tags, tags) && product.jp !== item.jp
        ? [{ key, ...item }]
        : [];
    });

    return filteredItemsWithKeys;
  };

  // Update similar items whenever the product or listings change
  useEffect(() => {
    setSimilarItems(getSimilarItems());
  }, [product, data]);

  // Function to scroll the container left
  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -300, behavior: 'smooth' }); // Adjust scroll amount as needed
      setTimeout(checkElements, 400)
    }
    
  };

  // Function to scroll the container right
  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 300, behavior: 'smooth' }); // Adjust scroll amount as needed
    }
    setTimeout(checkElements, 400)
  };

  return (
    similarItems.length > 0 ? (
      <Box sx={{ mb: 5, mt: 8, position: 'relative' }}>
        <Typography variant="h5" sx={{ mb: 3 }}>
          Similar Manga
        </Typography>
        <Box
          ref={containerRef}
          sx={{
            display: 'flex',
            overflowX: 'auto',
            overflowY: 'hidden',
            
            scrollbarWidth: 'none', // Hides scrollbar for Firefox
            '&::-webkit-scrollbar': { display: 'none' }, // Hides scrollbar for Webkit browsers
          }}
        >
          <Grid spacing={3} sx={{ display: "flex", flexDirection: "row", flexWrap: 'nowrap',maxWidth: '1em' }}>
            {similarItems.map((item, index) => (
              <Grid item key={index} >
                <Card sx={{ width: '15em' }} className="fade-in-target">
                  <CardMedia
                    component="img"
                    height="140"
                    image={'/covers' + item.img} // Ensure the path is correct
                    alt={item.key} // Display the key as alternative text
                    sx={{ objectFit: 'contain', padding: 1, cursor: 'pointer' }}
                    onClick={() => handleCardClick(item.key)}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div" sx={{ fontSize: '0.75em' }}>
                      {item.key.length > 20 ? item.key.substring(0, 20) + '...' : item.key}
                    </Typography>
                  </CardContent>
                  <Button variant="primary" sx={{backgroundColor: "rgba(0, 0, 0, 0.35)"}} onClick={() => handleCardClick(item.key)}>View</Button>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
        <IconButton
          onClick={scrollLeft}
          sx={{
            position: 'absolute',
            top: '50%',
            left: 0,

            boxShadow: 2,
            zIndex: 1,
          }}
        >
          <ChevronLeft />
        </IconButton>
        <IconButton
          onClick={scrollRight}
          sx={{
            position: 'absolute',
            top: '50%',
            right: 0,
            boxShadow: 2,
            zIndex: 1,
          }}
        >
          <ChevronRight />
        </IconButton>
      </Box>
    ) : null
  );
};

export default ViewSimilar;
