import React from 'react';
import { Helmet } from 'react-helmet';

const DynamicMetaComponent = ({ title, description, imageUrl }) => {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
      </Helmet>
      <h1>{title}</h1>
    </div>
  );
};

export default DynamicMetaComponent;
