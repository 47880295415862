import React, { useState, useEffect, useRef } from 'react';
import { Button, Slider, Box } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import Stop from '@mui/icons-material/Stop';
const AutoScrollBtn = () => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollSpeed, setScrollSpeed] = useState(5); // Default speed
  const scrollInterval = useRef(null);

  const handleScroll = () => {
    setIsScrolling(!isScrolling);
  };

  const handleSpeedChange = (event, newValue) => {
    setScrollSpeed(newValue);
  };

  useEffect(() => {
    if (isScrolling) {
      scrollInterval.current = setInterval(() => {
        window.scrollBy(0, 1); // Scroll by 1px
      }, 100 - scrollSpeed); // Adjust speed by inverse of slider value
    } else {
      clearInterval(scrollInterval.current);
    }

    return () => clearInterval(scrollInterval.current);
  }, [isScrolling, scrollSpeed]);

  return (
    <Box sx={{ width: 300, margin: '20px auto' }}>
      <Button color="secondary" variant='contained' sx={{width: '12em'}}onClick={handleScroll}>
        {isScrolling ? 'Stop Auto-Scroll' : 'Auto-Scroll'}
      </Button>
      {isScrolling && (
        <Box sx={{ marginTop: 2 }}>
          <Slider
            value={scrollSpeed}
            onChange={handleSpeedChange}
            aria-labelledby="scroll-speed-slider"
            min={5}
            max={100}
          />
        </Box>
      )}
      {isScrolling ? <Box sx={{
        position: 'fixed',
        top: '1em',
        right: '1em',
        transform: 'translateX(-50%)',
        width: '2em',
        padding: "0px"}}><Button sx={{minWidth: '3em',
          padding: "0px"}}color="error" variant="contained" onClick={handleScroll}><Stop sx={{p: 0}}/></Button></Box>:false}
    </Box>
  );
};

export default AutoScrollBtn;
