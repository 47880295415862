import * as React from 'react';
import { createTheme, ThemeProvider, CssBaseline, Box, Typography } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import getDashboardTheme from './theme/getDashboardTheme';
import checkElements from '../checkElements';
import SideMenu from './components/SideMenu';
import SearchBar from './components/SearchBar';
import Catalogue from './manga/Catalogue';
import mangas from './manga/manga.json';
import StreamedFileDownloader from './manga/StreamedFileDownloader';
import Cookies from 'js-cookie';
import MangaProfile from './components/MangaProfile';
import DynamicMetaComponent from './components/DynamicMetaComponent';

const Home = ({ setManga, setVols, handleClick }) => {
  const [search, setSearch] = React.useState('');

  React.useEffect(() => {
    checkElements();
      }, [])
  return (
    <Box sx={{ height: '100%', width: '100%', mt: 8, overflowX: 'hidden' }}>
      <DynamicMetaComponent title="HazyManga | RAW & ENGLISH | Read Online" description="Dive into our rich library of raw and English translated manga scans. Read manga free online, One Piece | Naruto | Black Clover | Dr Stone - Enjoy seamless online reading with premium-quality images and stay updated with the freshest chapters. From hidden gems to popular series, find recommendations tailored for manga lovers of all tastes and expertise." imageUrl="https://hazymanga.com/logo.png"/>
      <Box
        sx={{
          width: 200,
          height: 200,
          display: { xs: 'block', md: 'none' },
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '8px',
          overflow: 'hidden',
          margin: 'auto',
        }}
         className='fade-in-target'
      >
        <img
          src="/logo.png"
          alt="Example"
          style={{
            width: '100%',
            height: 'auto',
          }}
        />

      </Box>
      <SearchBar setSearch={setSearch} />
      <Box sx={{ width: { xs: '80%', lg: '40%' }, margin: 'auto', mb: 5 }}  className='fade-in-target'>
        <Typography variant="h1" sx={{ fontSize: '2em', marginTop: '1em', }} align="center">Read Manga for Free Online</Typography>
        <Typography>Welcome to HazyManga, your go-to site for reading manga online!<br></br>Enjoy a vast selection of manga in both raw and English translations, all completely free.<br />Dive into the latest chapters, explore classic series, and experience high-quality content with no subscriptions or hidden fees. Start your manga journey today!</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}>

        <Box
          sx={{
            height: 136,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '12px',
            overflow: 'hidden',
            margin: 'auto',
          }}
           className='fade-in-target'
        >

          <img
            src="/narutobanner.jpg"
            alt="Naruto Banner"
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </Box>
        {/* Optional additional banner */}
        {/* <Box
          sx={{
            width: 450,
            height: 136,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '12px',
            overflow: 'hidden',
            margin: 'auto',
            marginTop: '1em',
          }}
        >
          <img
            src="/onepiecbanner.jpg"
            alt="One Piece Banner"
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </Box> */}
      </Box>
      <Catalogue mangaList={mangas} setManga={setManga} search={search} setVols={setVols} />
    </Box>
  );
};


export default function Page() {
  const [mode, setMode] = React.useState('dark');
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);

  const dashboardTheme = createTheme(getDashboardTheme(mode));
  const [manga, setManga] = React.useState(false);
  const [readManga, setReadManga] = React.useState([]);
  const [vol, setVol] = React.useState(1);
  const [vols, setVols] = React.useState(1);
  
  React.useEffect(() => {
    window.addEventListener('scroll', checkElements);
    window.addEventListener('resize', checkElements);
    window.addEventListener('load', checkElements);
    window.addEventListener('popstate', checkElements);
    window.addEventListener('hashchange', checkElements);

    // Initial check in case elements are already in view
    document.addEventListener('DOMContentLoaded', checkElements);
    checkElements();

  }, []);
  React.useEffect(() => {
    checkElements();
      }, [manga])
  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  React.useEffect(() => {
    // Retrieve the cookie value and parse it
    const cookieValue = Cookies.get('readmangas');
    async function getSess() {
      const sessionIdResponse = await fetch('https://hazymanga.com/getSessionId');

      // Ensure you wait for the fetch response to be parsed as JSON
      const fetchResp = await sessionIdResponse.json();

      // Return the actual session ID from the parsed JSON
      return fetchResp.sessionId;
    }

    (async () => {
      // Await the session ID from the async function
      const sessId = await getSess();

      // Store the session ID in localStorage
      localStorage.setItem('session-id', sessId);

      // Verify it has been stored correctly
      console.log('Session ID stored:', localStorage.getItem('session-id'));
    })();
    // If cookie value exists, parse it as JSON
    if (cookieValue) {
      try {
        setReadManga(JSON.parse(cookieValue));
      } catch (error) {
        console.error('Failed to parse readmanga cookie:', error);
        setReadManga([]);
      }
    } else {
      setReadManga([]);
    }
  }, []); // Empty dependency array: runs only on component mount
  return (
    <ThemeProvider theme={dashboardTheme}>
      <Router>
        <CssBaseline />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <SideMenu readManga={readManga} setManga={setManga} />
          <Box sx={{ flexGrow: 1, width: '100vw' }}>

            <Routes>

              <Route path="/" element={<Home setManga={setManga} />} />

              <Route path="/read/:manga" element={<StreamedFileDownloader manga={manga} setReadManga={setReadManga} vol={vol} />} />

              <Route path="/manga/:mangaId" element={<MangaProfile setVol={setVol} setManga={setManga} manga={manga} />} />

            </Routes>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}
