import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Box, Pagination, TextField } from '@mui/material';
import { Card, CardContent, CardMedia, Typography, Grid, Button } from '@mui/material';
import { styled } from '@mui/system';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import mangaList from './manga.json';
import Fuse from 'fuse.js';
import { openPopUnder } from '../ads';
import { AdComponent } from '../ads';

// Define styled components
const CatalogueContainer = styled('div')({
  padding: '10px',
});

const MangaCard = styled(Card)({
  maxWidth: 280,
  height: 400,
  margin: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'transform 0.3s ease',
  '&:hover': {
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.2)',
    transform: 'scale(1.03)',
  },
  margin: 'auto',
});

const MangaCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
});

const MangaImage = styled(CardMedia)({
  height: '220px',
  width: '100%',
  objectFit: 'contain',
});

const MangaGrid = styled(Grid)({
  justifyContent: 'center',
});

const ITEMS_PER_PAGE = 24;

const Catalogue = ({ mangaList, setManga, search }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  

  // Filter mangaList based on search prop
  const filteredMangaList = useMemo(() => {
    if (!search) return mangaList;

    // Define the Fuse.js options
    const options = {
      keys: ['key', 'desc'], // Fields to search in
      includeScore: true, // Include the score in results
      threshold: 0.3, // Sensitivity (0.0 exact match, 1.0 very loose)
    };

    // Convert mangaList to an array of objects
    const mangaArray = Object.keys(mangaList).map(key => ({
      key,
      ...mangaList[key],
    }));

    // Initialize Fuse.js with the manga array and options
    const fuse = new Fuse(mangaArray, options);

    // Perform the search
    const results = fuse.search(search);

    // Convert results back to the original format
    const resultMangaList = results.reduce((acc, { item }) => {
      acc[item.key] = item;
      return acc;
    }, {});

    return resultMangaList;
  }, [mangaList, search]); // Dependencies array

  

  // Calculate the number of pages
  const totalPages = Math.ceil(Object.keys(filteredMangaList).length / ITEMS_PER_PAGE);

  // Get the current manga items to display
  const currentMangaKeys = Object.keys(filteredMangaList).slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );
  const handleClick = (mangaKey) => {
    if(Math.random() < 0.3) {
      openPopUnder();
      
    }
    setManga(mangaKey);
    //setVols(mangaList[mangaKey].vols)
    navigate('/read/' + mangaKey);
  };
  const handleCardClick = (mangaKey) => {
    navigate('/manga/' + mangaKey)
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleBottomPagination = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({
      top: 100,
      behaviour: 'smooth'
    })
  }
  return (
    <CatalogueContainer>
      <Typography variant="h4" gutterBottom align="center" className='fade-in-target'>
        Browse Manga
      </Typography>
      {search.length !== 0 ? <Typography sx={{position: 'absolute', left: {xs: '50%', md: "360px"}, transform: {xs: 'translateX(-50%)', md: 'translateX(0)'}}}  className='fade-in-target'>"{search}"</Typography>:false}
    
      {/* Pagination Controls */}
      <Box display="flex" justifyContent="center" marginTop={3}  className='fade-in-target'>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          sx={{mb: 3}}
        />
      </Box>
      
      <MangaGrid container spacing={1}>
        {currentMangaKeys.slice(0, currentMangaKeys.length / 2).map((mangaKey, index) => {
          const manga = filteredMangaList[mangaKey];
          return (
            <>
            <Grid item xs={12} sm={6} md={4.5} lg={2.5} key={mangaKey}  className='fade-in-target'>
              
              <MangaCard>
                <MangaImage
                  component="img"
                  image={'/covers' + manga.img}
                  alt={mangaKey}
                  onClick={(e) => {e.preventDefault(); handleCardClick(mangaKey)}}
                  sx={{cursor: 'pointer'}}
                />
                {manga.v && manga.v.raw ? (
                  <Typography
                    variant="body2"
                    sx={{
                      borderRadius: '4px',
                      position: 'absolute',
                      fontSize: '0.8em',
                      backgroundColor: '#0fd71c',
                      width: '4em',
                      p: 0.4,
                    }}
                  >
                    RAW
                  </Typography>
                ) : null}
                {manga.v && manga.v.eng ? (
                  <Typography
                    variant="body2"
                    sx={{
                      borderRadius: '4px',
                      position: 'absolute',
                      marginTop: manga.v.raw ? '2em' : '0px',
                      fontSize: '0.8em',
                      backgroundColor: '#0085f1',
                      width: '4em',
                      p: 0.4,
                    }}
                  >
                    ENG
                  </Typography>
                ) : null}
                <MangaCardContent>
                  <Typography variant="h6" component="div" gutterBottom>
                    {mangaKey.charAt(0).toUpperCase() + mangaKey.slice(1)}
                  </Typography>
                  <Typography variant="body2" color="text.primary">
                    Volumes: {manga.vols}
                  </Typography>
                  {/* <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      textAlign: 'left',
                      width: '100%',
                    }}
                    
                  >
                    <VisibilityIcon sx={{ fontSize: '1.5em', mr: 1 }} />
                    <Typography variant="body2" color="text.primary">
                      {manga.views || 0}
                    </Typography>
                  </Box> */}
                  <Button
                    onClick={(e) => {e.preventDefault(); handleClick(mangaKey)}}
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: '10px' }}
                  >
                    Read Now
                  </Button>
                  
                </MangaCardContent>
                
              </MangaCard>
            </Grid>
              
              </>
          );
        })}<Grid item xs={12} sm={6} md={4.5} lg={2.5} key='ad1'><AdComponent id="interAd1" /></Grid><Grid item xs={12} sm={6} md={4.5} lg={2.5} key='ad2'><AdComponent id="interAd2"/></Grid><Grid item xs={12} sm={6} md={4.5} lg={2.5} key='ad3'><AdComponent id="interAd3"/></Grid><Grid item xs={12} sm={6} md={4.5} lg={2.5} key='ad4'><AdComponent id="interAd4"/></Grid>
                {currentMangaKeys.slice(currentMangaKeys.length / 2, currentMangaKeys.length).map((mangaKey, index) => {
          const manga = filteredMangaList[mangaKey];
          return (
            <>
            <Grid item xs={12} sm={6} md={4.5} lg={2.5} key={mangaKey}>
              
              <MangaCard>
                <MangaImage
                  component="img"
                  image={'/covers' + manga.img}
                  alt={mangaKey}
                  onClick={(e) => {e.preventDefault(); handleCardClick(mangaKey)}}
                  sx={{cursor: 'pointer'}}
                />
                {manga.v && manga.v.raw ? (
                  <Typography
                    variant="body2"
                    sx={{
                      borderRadius: '4px',
                      position: 'absolute',
                      fontSize: '0.8em',
                      backgroundColor: 'green',
                      width: '4em',
                      p: 0.4,
                    }}
                  >
                    RAW
                  </Typography>
                ) : null}
                {manga.v && manga.v.eng ? (
                  <Typography
                    variant="body2"
                    sx={{
                      borderRadius: '4px',
                      position: 'absolute',
                      marginTop: manga.v.raw ? '2em' : '0px',
                      fontSize: '0.8em',
                      backgroundColor: 'blue',
                      width: '4em',
                      p: 0.4,
                    }}
                  >
                    ENG
                  </Typography>
                ) : null}
                <MangaCardContent>
                  <Typography variant="h6" component="div" gutterBottom>
                    {mangaKey.charAt(0).toUpperCase() + mangaKey.slice(1)}
                  </Typography>
                  <Typography variant="body2" color="text.primary">
                    Volumes: {manga.vols}
                  </Typography>
                 {/*<Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      textAlign: 'left',
                      width: '100%',
                    }}
                    
                  >
                    <VisibilityIcon sx={{ fontSize: '1.5em', mr: 1 }} />
                    <Typography variant="body2" color="text.primary">
                      {manga.views || 0}
                    </Typography>
                  </Box>*/}
                  <Button
                    onClick={(e) => {e.preventDefault(); handleClick(mangaKey)}}
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: '10px' }}
                  >
                    Read Now
                  </Button>
                  
                </MangaCardContent>
                
              </MangaCard>
            </Grid>
              
              </>
          );
        })}
      </MangaGrid>
        
      {/* Pagination Controls */}
      <Box display="flex" justifyContent="center" marginTop={3} marginBottom={3}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleBottomPagination}
          color="primary"
        />
      </Box>
      <Box sx={{width: 'max-content', margin: 'auto', display: 'flex', flexDirection: {xs: 'column', xl: 'row'}}}><AdComponent id="bottomCat"/><AdComponent id="bottomCat2"/><AdComponent id="bottomCat3"/><AdComponent id="bottomCat4"/></Box>
    </CatalogueContainer>
    
  );
};

export default Catalogue;
